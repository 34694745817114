<template>
  <div class="container">
    <!-- 居中 -->
    <van-row v-if="loading" class="logo" type="flex" justify="center">
      <van-image round width="8rem" height="8rem" fit="cover" :src="src" />
    </van-row>
    <!-- <div class="title">未莱掌柜宝</div> -->
    <van-cell-group v-if="loading" >
      <van-field colon label-align="center" v-model.trim="form.username" label="姓名" placeholder="请输入姓名" required autofocus>
      </van-field>
      <van-field required colon label-align="center" v-model.trim="form.mchntId" label="商户号" placeholder="请输入五位数商户号" autofocus>
      </van-field>
      <van-field ref="phone" colon label-align="center" v-model.trim="form.phone" label="手机号" placeholder="请输入手机号" type="tel"
        maxlength="11" required autofocus>
      </van-field>
      <van-field colon v-model.trim="form.code" label-align="center" label="验证码" placeholder="请输入验证码" type="tel" maxlength="6"
        required>
        <template #button>
          <van-button plain class="send_btn" type="info" size="small" :loading="load.code" loading-text="发送中..." @click="send">
            {{ btn }}</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <div class="sub_div" v-if="loading" >
      <van-button :loading="load.sub" loading-text="激活中..." class="sub_btn" type="info" block plain @click="submit">
        立即激活</van-button>
    </div>
    <van-popup class="popup" v-model="popup"><img class="popup" src="@/assets/b.gif" alt="" /></van-popup>
  </div>
</template>
<script>
import util from "../../util/util";
import {
  Field, Cell, CellGroup, Toast, Popup, PasswordInput, NumberKeyboard, Button, Col, Row, Image as VanImage
} from 'vant';
import { WX_APP_ID, ALI_APP_ID } from '../../util/constant';
export default {
  components: {
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    [PasswordInput.name]: PasswordInput,
    [NumberKeyboard.name]: NumberKeyboard,
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      loading:false,//全局的loading
      src: require("@/assets/logo.png"),
      form: {
        username: '',
        phone: '',
        code: '',
        mchntId: '',
        openId: undefined,
        id: undefined,
      },
      load: {
        code: false,
        sub: false
      },
      btn: '获取验证码',
      num: 60,
      popup: false,
      source: null
    }
  },
  mounted: function () {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.popup = true
      let browser = util.isAlipayOrWechat();
      // 获取当前url
      let url = window.location.href;
      // 当前路由参数
      let query = this.$route.query;
      // 获取当前url中的id参数
      this.form.id = query.id;
      if (browser == 'wechat') {
        this.source = 0
        // 判断当前url是否包含code参数
        if (query.code) {
          // 获取openid
          this.getOpenId(query.code);
        } else {
          // 如果不包含code参数，则跳转到微信授权页面
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WX_APP_ID}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        }
      } else if (browser == 'alipay') {
        this.source = 1;
        // 判断当前url是否包含auth_code参数
        if (query.auth_code) {
          // 获取openid
          this.getUserId(query.auth_code);
        } else {
          // 如果不包含code参数，则跳转到微信授权页面
          window.location.href = `https://openauth.alipaydev.com/oauth2/publicAppAuthorize.htm?app_id=${ALI_APP_ID}&scope=auth_base&redirect_uri=${encodeURIComponent(url)}`
        }
      } else {
        Toast("请使用微信扫描二维码");
      }
    },
    // 支付宝获取userid
    getUserId(code) {
      let openId = util.getData('openId')
      if (!openId) {
        this.$get('/jsapi/get_userid', {
          code: code
        }).then(res => {
          if (res.code == 200) {
            this.popup = false;
            this.loading=true;//获取到openid后，渲染页面
            util.setData('openId', res.data);
            this.form.openId = res.data
          }
        })
      } else {
        this.popup = false
        this.form.openId = openId
      }
    },
    // 微信获取openid
    getOpenId(code) {
      let openId = util.getData('openId')
      if (!openId) {
        this.$get('/jsapi/get_openid', {
          code: code
        }).then(res => {
          if (res.code == 200) {
            this.popup = false;
            this.loading=true;//获取到openid后，渲染页面
            util.setData('openId', res.data);
            this.form.openId = res.data
          }
        })
      } else {
        this.popup = false
        this.form.openId = openId
      }
    },
    submit() {

      if (!this.form.username) {
        Toast('请输入姓名');
        return;
      }
      if(!this.form.mchntId){
        Toast('请输入五位数商户号');
        return
      }
      const regex = /^1[3456789]\d{9}$/;
      if (!regex.test(this.form.phone)) {
        Toast('请输入正确的手机号');
        return
      }
      if (!this.form.code) {
        Toast('请输入验证码');
        return
      }
      this.load.sub = true;

      this.$post('/jsapi/active', {
        openid: this.form.openId,
        id: this.form.id,
        phone: this.form.phone,
        username: this.form.username,
        code: this.form.code,
        mchntId: this.form.mchntId,
        source: this.source
      }).then(res => {
        if (res.code == 200) {
          Toast('激活成功');
          const  data = {
            phone: this.form.phone,
            username: this.form.username,
            mchntId: this.form.mchntId,
          }
          window.console.log(data);
          //激活成功页面
          this.$router.push({
            name:"activationSuccess",
            path:"activationSuccess",
            query:{
              phone: this.form.phone,
              username: this.form.username,
              mchntId: this.form.mchntId,
            },
          })
          this.form.phone = null
          this.form.code = null
          this.form.username = null
          this.form.mchntId = null
        }
      }).finally(() => {
        this.load.sub = false
      })
    },
    send() {
      const regex = /^1[3456789]\d{9}$/;
      if (!regex.test(this.form.phone)) {
        Toast('请输入正确的手机号');
        return
      }
      // 按钮不等于获取验证码返回
      if (this.btn == '获取验证码') {
        this.load.code = true
        // 发送验证码
        this.$get('/jsapi/send_code', {
          phone: this.form.phone,
        }).then(res => {
          if (res.code == 200) {
            Toast("发送成功");
            this.num = 60;
            let timer = setInterval(() => {
              this.num--;
              this.btn = this.num + 's后重发';
              if (this.num <= 0) {
                this.btn = '获取验证码';
                clearInterval(timer);
              }
            }, 1000);
          }
        }).finally(() => {
          this.load.code = false
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  .popup {
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
  .sub_div {
    padding: 10px;
    .sub_btn {
      margin-top: 5rem;
    }
  }
  .send_btn {
    // width: 100%;
  }
  .title {
    text-align: center;
    font-size: 26px;
    margin: 10px 0px 35px 0px;
    color: #33333394;
  }
  .logo {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>